<template>
  <div class="container">

    <div class="card p-3 mt-3">
      <h3 class="mt-4 mb-3">Herzlich Willkommen {{ userName }}!</h3>

      <p>Die Plattform befindet sich noch im Aufbau. Aktuell funktioniert folgendes:</p>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">Online-Hautberatungen</div>
            <div class="card-body">
              <p>Kunden können Hautberatungs-Anfragen abschicken und du bekommst eine Benachrichtigung und kannst dem Kunden antworten.</p>

              <router-link class="btn btn-primary me-3" to="/hautberatungen">Hautberatungs-Anfragen verwalten</router-link>
              <router-link class="btn btn-primary" to="/hautberatung">Hautberatungs-Formular ansehen</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Overview",

  computed: {
    userName() {
      return this.$store.state.auth.account.name;
    }
  }
}
</script>

<style scoped>

</style>